import React from "react";
import { View, Text, TouchableOpacity, Linking, Image } from "react-native";
import styles from "../Styles/HomeStyles";
import logo from "../assets/logo.png";

const Home = ({ navigation }) => {
	// Function to handle email button press
	const handleEmailPress = () => {
		Linking.openURL("mailto:info@lua.to");
	};

	// Function to handle call button press
	const handleCallPress = () => {
		Linking.openURL("tel:3235393202");
	};

	// Function to navigate to the services page
	const handleServicesPress = () => {
		// Replace 'ServicesPage' with the actual name of your services page route
		navigation.navigate("Services");
	};

	return (
		<View style={styles.body}>
			<View style={styles.container}>
				<Image source={logo} style={styles.logo} resizeMode="contain" />
				<View style={styles.infoContainer}>
					<Text style={styles.title}>Welcome to Lua!</Text>

					<Text style={styles.infoText}>
						We are still working on this site, but you can download Lua from the Google Chrome Store <a href="https://chromewebstore.google.com/detail/lua-the-smart-language-le/bemjplmmkajbhjkanaomdghjbajjdbnk?hl=fr&utm_source=ext_sidebar">here</a> or from our sister site <a href="https://www.makenoisemedia.io/blog">here</a>!{/* </Text><Text style={styles.infoText}>Contact us today! */}
					</Text>
				</View>

				<View style={styles.buttonContainer}>
					<TouchableOpacity style={styles.button} onPress={handleEmailPress}>
						<Text style={styles.buttonText}>Drop Us a Line!</Text>
					</TouchableOpacity>

					{/* <TouchableOpacity style={styles.button} onPress={handleServicesPress}>
						<Text style={styles.buttonText}>Our Services</Text>
					</TouchableOpacity> */}

					{/* <TouchableOpacity style={styles.button} onPress={handleCallPress}>
						<Text style={styles.buttonText}>Message By Phone</Text>
					</TouchableOpacity> */}
				</View>
			</View>
		</View>
	);
};

export default Home;
